import React from 'react';
import { useState } from 'react';
import postData from '../../api/send-contact-form';

const validateEmail = (email) => {
    if (!email) {
        return false;
    }
    const regex = /^[a-zA-Z0-9_.-]+@+\S+[.][a-z]{2,}/g;

    return email.match(regex);
};

const validatePhone = (tel) => {
    if (!tel) {
        return false;
    }
    const regex = /^\d{3} *\d{3} *\d{2} *\d{2}$/g;


    return tel.match(regex);
};

const handleErrors = (val) => {
    const handleErrors = { tel: null, email: null };
    if (!val.email) {
        handleErrors.email = 'Das Feld muss ausgefüllt sein.'
    }

    if (!val.tel) {
        handleErrors.tel = 'Das Feld muss ausgefüllt sein.'
    }

    if (val.email && !validateEmail(val.email)) {
        handleErrors.email = 'Die Email stimmt nicht.'
    }

    if (val.tel && !validatePhone(val.tel)) {
        handleErrors.tel = 'Die Telefonnummer stimmt nicht.';
    }
    return handleErrors;
};


export const ContactForm = () => {
    const [form, setForm] = useState({ email: null, tel: null, name: null, message: null });
    const [error, setError] = useState({ email: null, tel: null });
    const [success, setSuccess] = useState();
    const sendForm = async (form) => {
        

        if (!validateEmail(form.email) || !validatePhone(form.tel)) {
            setError(handleErrors(form));
            return;
        }
        postData(`https://www.elianetobler.ch/api/sendForm`, form).then(data => {
            setSuccess(`Vielen Dank ${ form.name }, ich habe Deine Nachricht erhalten und werde mich bald möglichst bei Dir melden.`)
        }).catch(err => {
            console.log(err);
            setError({ fetch: 'Leider ist ein Fehler aufgetretten. Die Email konnte nicht versand werden.' })
        })
    };

    const handleOnBlur = (e) => {
        setForm(e);
        setError(handleErrors(e));
    };

    return (
        <div className={ `contact-form ${ success && 'contact-fomr__success' }` }>
            { success ? <p>{ success }</p> :
                <form autoComplete='on'>
                    <label className='contact-form--label'>Name</label>
                    <input type='text' name='name'
                        className='contact-form--input'
                        onBlur={ (e) => handleOnBlur({ ...form, name: e.currentTarget.value }) }
                        placeholder='Vorname Nachname'/>

                    <label className='contact-form--label contact-form--email'>eMail</label>
                    <input type='text' name='email'
                        className='contact-form--input'
                        onBlur={ (e) => handleOnBlur({ ...form, email: e.currentTarget.value }) }
                        placeholder='beispiel@beispiel.ch'/>
                    { error.email && <div className='contact-form--error'>{ error.email }</div> }

                    <label className='contact-form--label'>Telefon</label>
                    <input type='tel' name='phone'
                        className='contact-form--input'
                        onBlur={ (e) => handleOnBlur({ ...form, tel: e.currentTarget.value }) }
                        placeholder='079 123 45 67'/>
                    { error.tel && <div className='contact-form--error'>{ error.tel }</div> }

                    <label className='contact-form--label'>Nachricht</label>
                    <textarea className='contact-form--textarea' name='message'
                        onBlur={ (e) => handleOnBlur({ ...form, message: e.currentTarget.value }) }/>

                    <button type='button'
                        className='button-base contact-form--button'
                        onClick={ () => {
                            sendForm(form);
                        } }>Senden
                    </button>

                    { error.fetch && <p>{ error.fetch }</p> }
                </form> }
        </div>
    )
};
