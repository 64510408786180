import React, {useState} from 'react';
import {useRef} from 'react';
import ColorPicker from '../colorpicker/colorpicker';
import {connect} from 'react-redux';
import {Route} from "react-router";
import {setColorData} from "../../api/set-colors";
import {colors} from "../../redux/colors-reducer/colors-action";
import MethodContent from './method-content';
import {useBackground} from "../utils/use-background";

const mapStateToProps = state => ({
    colors: state.colors,
    scroll: state.scroll,
    content: state.content,
    canEdit: state.canEdit
});

const mapDispatchToProps = dispatch => ({
    setColors: cols => dispatch(colors(cols)),
});

export const Method = ({match, setColors, colors, content, canEdit}) => {
    const [hasColorPicker, setHasColorPicker] = useState(false);
    const methodContent = content.method;
    const methodRef = useRef(null);
    const methodRefBefore = useRef(null);
    const setBG = useBackground(methodRef, methodRefBefore);

    const saveData = async () => {
        await setColorData(colors.method, 'method');
    };

    const resetData = async (key) => {
        colors.method = {...colors.method, [key]: colors.method.backup[key]};
        await setColorData(colors.method, 'method');
        setColors(colors.method);
    };


    const methodSection = useRef(null);

    return (<div className="method" ref={methodRef}>
            <div className='method--before' ref={methodRefBefore}>
                <div className='method--before-bg' style={{
                    background: `radial-gradient(${colors.method.innerColor.hsl} 0%, ${colors.method.outerColor.hsl} 100%)`,
                    height: `${setBG.height}px`
                }}/>
            </div>
            <div className='method--content-bg' style={{ top: `${setBG.offsetTop}px` }}>
                <div className='method--before-bg' style={{
                    background: `radial-gradient(${colors.method.innerColor.hsl} 0%, ${colors.method.outerColor.hsl} 100%)`,
                    height: `${setBG.height}px`,
                    top: `-${setBG.offsetTop}px`
                }}/>
            </div>

            {hasColorPicker  && <ColorPicker onChange={col => setColors(colors.method.innerColor = col)}
                                            value={colors.method.innerColor} save={saveData} reset={() => resetData('innerColor')}/>}
            {hasColorPicker && <ColorPicker onChange={col => setColors(colors.method.outerColor = col)}
                                            outer value={colors.method.outerColor} save={saveData} reset={() => resetData('outerColor')}/>}
            <div className='method--spacer'/>
            <section className="method--section grid main-grid"
                     ref={methodSection}>
                <article className="method--content">
                    <Route exact path={`${match.path}changecolor/`} component={() => <button onClick={() => {
                        setHasColorPicker(!hasColorPicker)
                    }}>change color
                    </button>}/>
                    <MethodContent content={methodContent} editable={canEdit}/>
                </article>
            </section>
            <div className='method--after'/>
        </div>
    )
};

export default connect(mapStateToProps, mapDispatchToProps)(Method);
