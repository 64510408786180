import React, {useRef, useState, useMemo} from 'react';
import keyViz from '../../Media/keyViz.png';
import ColorPicker from '../colorpicker/colorpicker';
import {Route} from "react-router";
import {connect} from "react-redux";
import {setColorData} from "../../api/set-colors";
import {colors} from "../../redux/colors-reducer/colors-action";
import {handleEditeMode, handleEditeModeBlur, saveValue} from "../utils/handle-edit-mode";

const mapStateToProps = state => ({
    colors: state.colors,
    content: state.content,
    editable: state.canEdit
});

const mapDispatchToProps = dispatch => ({
    setColors: cols => dispatch(colors(cols))
});

export const Header = ({match, colors, setColors, content, editable}) => {
    const [hasColorPicker, setHasColorPicker] = useState(false);
    const [shouldSave, setShouldSave] = useState(false);
    const [title, setTitle] = useState(content.header.title);
    const [subtitle, setsubtitle] = useState(content.header.subtitle);

    const section = useRef();

    const saveData = async () => {
        await setColorData(colors.header, 'header');
    };

    const resetData = async (key) => {
        colors.header = {...colors.header, [key]: colors.header.backup[key]};
        await setColorData(colors.header, 'header');
        setColors(colors.header);
    };

    const onTitleBlur = (e, value) => {
        setTitle(value);
        setShouldSave(true);
        handleEditeModeBlur(e);
    };
    const onSubitleBlur = (e, value) => {
        setsubtitle(value);
        setShouldSave(true);
        handleEditeModeBlur(e);
    };

    useMemo(async () => {
        shouldSave && await saveValue(null, 'header', {title, subtitle});
        setShouldSave(false);
    }, [title, subtitle, shouldSave]);


    return (
        <header className="header"
                ref={section}
                style={{background: `radial-gradient(at 50% 26%, ${colors.header.innerColor.hsl} 0%, ${colors.header.outerColor.hsl} 100%)`}}>

            {hasColorPicker &&
            <ColorPicker onChange={col => setColors(colors.header.innerColor = col)} value={colors.header.innerColor} save={saveData}
                         reset={() => resetData('innerColor')}/>}
            {hasColorPicker &&
            <ColorPicker onChange={col => setColors(colors.header.outerColor = col)}
                         value={colors.header.outerColor} save={saveData} reset={() => resetData('outerColor')}
                         outer/>}

            <div className="grid main-grid header--grid-container">
                <div className='header--grid-content'>
                    <img className="header--keyViz"
                         src={keyViz}
                         alt="Geburtsgeschichten"/>
                    <section className="header--title">
                        <Route exact path={`${match.path}changecolor/`} component={() => <button onClick={() => {
                            setHasColorPicker(!hasColorPicker)
                        }}>change color
                        </button>}/>
                        <h1 className={`${editable && 'edit'}`} onClick={(e) => editable && handleEditeMode(e)}
                            onBlur={(e) => editable && onTitleBlur(e, e.currentTarget.innerText)}>
                            {title}</h1>
                        <h2 className={` ${editable && 'edit'}`} onClick={(e) => editable && handleEditeMode(e)}
                            onBlur={(e) => editable && onSubitleBlur(e, e.currentTarget.innerText)}>
                            {subtitle}</h2>
                    </section>
                </div>
            </div>
            <div className='header--spacer'/>
        </header>
    )
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
