import React, {useState, useMemo} from 'react';
import {handleEditeMode, handleEditeModeBlur, saveValue} from "../utils/handle-edit-mode";
import {convertToRichText} from "../utils/convert-string-to-richtext";


const AboutContent = ({content, editable}) => {
    const [title, setTitle] = useState(content.title);
    const [entries, setEntries] = useState(content.entries);
    const [shouldSave, setShouldSave] = useState(false);

    const onTitleBlur = (e, value) => {
        setTitle(value);
        setShouldSave(true);
        handleEditeModeBlur(e);
    };

    const onEntryBlur = (e, value, index) => {
        setEntries({...entries, [index]: {...entries[index], ...value}});
        setShouldSave(true);
        handleEditeModeBlur(e);
    };

    const addArticle = () => {
        const lastEntry = Object.values(entries).length;
        setEntries({...entries, [lastEntry]: {quote: '', title: '', text: ''}});
        setShouldSave(true);
    };

    const deleteEntry = (index) => {
        const entryArray = Object.values(entries);

        entryArray.splice(index, 1);

        setEntries(entryArray.reduce((acc, current, index) => {
            acc[index] = current;
            return acc;
        }, {}));

        setShouldSave(true);

    };

    useMemo(async () => {
        shouldSave && await saveValue(null, 'about', {title, entries});
        setShouldSave(false);
    }, [title, entries, shouldSave]);

    const renderEntries = Object.values(entries).map((entry, index) => {
        return (<div key={index}>
            {editable || entry.title ? <h2 className={`about--article-title ${editable && 'edit'}`} onClick={(e) => editable && handleEditeMode(e)}
                onBlur={(e) => editable && onEntryBlur(e, {title: e.currentTarget.innerText}, index)}>{entry.title}
            </h2> : null }
            {editable || entry.text ?  <p className={`about--content-text ${editable && 'edit'}`}
               onClick={(e) => editable && handleEditeMode(e)}
               onBlur={(e) => editable && onEntryBlur(e, {text: e.currentTarget.innerText}, index)}
            >
                {editable ? entry.text : convertToRichText(entry.text)}
            </p> : null }
            {editable || entry.quote ? <p className={`about--content-quote ${editable && 'edit'}`}
               onClick={(e) => editable && handleEditeMode(e)}
               onBlur={(e) => editable && onEntryBlur(e, {quote: e.currentTarget.innerText}, index)}
            >
                {entry.quote}
            </p> : null }
            {editable && <button className='button-base delete-entry' onClick={() => deleteEntry(index)}>Artikel löschen</button>}
        </div>)
    });

    return (
        <>
            <h1 className={`about--content-title ${editable && 'edit'}`}
                onClick={(e) => editable && handleEditeMode(e)}
                onBlur={(e) => editable && onTitleBlur(e, e.currentTarget.innerText)}>
                {title}</h1>
            {renderEntries}
            {editable && <button className='button-base add-entry' onClick={addArticle}>Artikel hinzufügen</button>}
        </>
    )
};

export default AboutContent;
