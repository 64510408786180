import React, {useState, useMemo} from 'react';
import {saveValue, handleEditeMode, handleEditeModeBlur} from "../utils/handle-edit-mode";
import {convertToRichText} from "../utils/convert-string-to-richtext";


export const MethodContent = ({content, editable}) => {
    const [shouldSave, setShouldSave] = useState(false);
    const [title, setTitle] = useState(content.title);
    const [entries, setEntries] = useState(content.entries);

    const onTitleBlur = (e, value) => {
        setTitle(value);
        setShouldSave(true);
        handleEditeModeBlur(e);
    };

    const onEntryBlur = (e, value, index) => {
        setEntries({...entries, [index]: {...entries[index], ...value}});
        setShouldSave(true);
        handleEditeModeBlur(e);
    };

    const addArticle = () => {
        const lastEntry = Object.values(entries).length;
        setEntries({...entries, [lastEntry]: {quote: '', title: '', text: ''}});
        setShouldSave(true);
    };

    const deleteEntry = (index) => {
        const entryArray = Object.values(entries);

        entryArray.splice(index, 1);

        setEntries(entryArray.reduce((acc, current, index) => {
            acc[index] = current;
            return acc;
        }, {}));

        setShouldSave(true);

    };

    useMemo(async () => {
        shouldSave && await saveValue(null, 'method', {title, entries});
        setShouldSave(false);
    }, [title, entries, shouldSave]);

    const renderContent = Object.values(entries).map((entry, index) => {
        return (
            <div key={index}>
                {editable || entry.title ?
                    <h2 className={`method--article-title ${editable && 'edit'}`} onClick={(e) => editable && handleEditeMode(e)}
                        onBlur={(e) => editable && onEntryBlur(e, {title: e.currentTarget.innerText}, index)}>{entry.title}
                    </h2> : null}
                {editable || entry.text ? <p className={`method--body ${editable && 'edit'}`} onClick={(e) => editable && handleEditeMode(e)}
                                             onBlur={(e) => editable && onEntryBlur(e, {text: e.currentTarget.innerText}, index)}
                >
                    {editable ? entry.text : convertToRichText(entry.text)}
                </p> : null}
                {editable || entry.quote ? <p className={`method--quote ${editable && 'edit'}`}
                                              onClick={(e) => editable && handleEditeMode(e)}
                                              onBlur={(e) => editable && onEntryBlur(e, {quote: e.currentTarget.innerText}, index)}>{entry.quote}
                </p> : null}
                {editable && <button className='button-base delete-entry' onClick={() => deleteEntry(index)}>Artikel löschen</button>}
            </div>
        )
    });

    return (
        <>
            <h1 className={`method--content-title ${editable && 'edit'}`}
                onClick={(e) => editable && handleEditeMode(e)}
                onBlur={(e) => editable && onTitleBlur(e, e.currentTarget.innerText)}>{title}</h1>
            {renderContent}
            {editable && <button className='button-base add-entry' onClick={addArticle}>Artikel hinzufügen</button>}
        </>
    )
        ;
};

export default MethodContent;
