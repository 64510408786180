import React, {useRef, useState} from 'react';
import {connect} from 'react-redux';
import {Route} from "react-router";
import ColorPicker from "../colorpicker/colorpicker";
import {colors} from "../../redux/colors-reducer/colors-action";
import {setColorData} from "../../api/set-colors";
import {ReactComponent as EventLine01} from "../../Media/eventLine_01.svg";
import {ReactComponent as EventLine02} from "../../Media/eventLine_02.svg";
import {ReactComponent as EventLine03} from "../../Media/eventLine_03.svg";
import {ReactComponent as EventLine04} from "../../Media/eventLine_04.svg";
import {saveValue, handleEditeMode, handleEditeModeBlur} from "../utils/handle-edit-mode";
import {useMemo} from 'react';
import {convertToRichText} from "../utils/convert-string-to-richtext";
import {useBackground} from "../utils/use-background";

const mapStateToProps = state => ({
    colors: state.colors,
    scroll: state.scroll,
    content: state.content,
    canEdit: state.canEdit,
});

const mapDispatchToProps = dispatch => ({
    setColors: cols => dispatch(colors(cols))
});

const eventLines = [<EventLine01 className='events--line'/>, <EventLine02 className='events--line'/>, <EventLine03 className='events--line'/>,
    <EventLine04 className='events--line'/>];


export const Events = ({match, colors, setColors, content, canEdit}) => {
    const [shouldSave, setShouldSave] = useState(false);
    const [entries, setEntries] = useState(content.events.entries);
    const [title, setTitle] = useState(content.events.title);
    const eventsRef = useRef(null);
    const eventsRefBefore = useRef(null);
    const setBG = useBackground(eventsRef, eventsRefBefore);
    
    const onEventsBlur = (e, value, index) => {
        setEntries({...entries, [index]: {...entries[index], ...value}});
        setShouldSave(true);
    };

    const onTitleBlur = (e, value) => {
        setTitle(value);
        setShouldSave(true);
        handleEditeModeBlur(e);
    };

    const saveData = async () => {
        await setColorData(colors.events, 'events');
    };

    const resetData = async (key) => {
        colors.events = {...colors.events, [key]: colors.events.backup[key]};
        await setColorData(colors.events, 'events');
        setColors(colors.events);
    };

    const eventsSection = useRef(null);
    const [hasColorPicker, setHasColorPicker] = useState(false);


    const dateLocation = (date, location, index) => <h3 className='events--date-title'>{canEdit &&
    <button className='button-base events--delete' onClick={() => deleteEvent(index)}>Event löschen</button>}<span
        onClick={(e) => canEdit && handleEditeMode(e)}
        onBlur={(e) => canEdit && onEventsBlur(e, {date: e.currentTarget.innerText}, index)} className={canEdit ? 'edit' : ''}>{date}</span>, <span
        onClick={(e) => canEdit && handleEditeMode(e)}
        onBlur={(e) => canEdit && onEventsBlur(e, {location: e.currentTarget.innerText}, index)} className={canEdit ? 'edit' : ''}>{location}</span>
    </h3>;

    const dateDescription = (description, index) => <p className={`events--date-text ${canEdit && 'edit'}`}
                                                       onClick={(e) => canEdit && handleEditeMode(e)}
                                                       onBlur={(e) => canEdit && onEventsBlur(e, {description: e.currentTarget.innerText}, index)}>
        {canEdit ? description : convertToRichText(description)}
    </p>;


    const renderEventRow = (date, location, description, isRight = false, key, isLast, eventline) => (
        <article className={`events--date ${isRight ? '' : 'events--text__right'}`} key={key}>
            {isRight ? <div className='events--empty-slot'/> :
                dateLocation(date, location, key)
            }
            <div className="events--date-sep"/>
            {isRight ? dateLocation(date, location, key) :
                <div className='events--empty-slot'/>
            }
            {isRight ? <div className='events--empty-slot'/> :
                dateDescription(description, key)
            }
            {isLast ? <div/> : <div className='events--line-wrapper'>{eventline}</div>}
            {isRight ? dateDescription(description, key) :
                <div className='events--empty-slot'/>
            }
        </article>

    );

    const pushEvent = () => {
        setEntries({...entries, [Object.entries(entries).length]: {date: '00. Januar', location: 'Ort', description: 'Beschreibung'}});
        setShouldSave(true);
    };

    useMemo(async () => {
        shouldSave && await saveValue(null, 'events', {title, entries});
        setShouldSave(false);
    }, [title, entries, shouldSave]);

    const deleteEvent = async (index) => {
        const eventArray = Object.values(entries);
        eventArray.splice(index, 1);

        setEntries(
            eventArray.reduce(
                (acc, value, key) => {
                    acc[key] = value;
                    return acc;
                }
                , {}
            )
        );
        setShouldSave(true);
    };

    return (
        <div className="events" ref={eventsRef}>
            <div className='events--before' ref={eventsRefBefore}>
                <div className='events--before-bg' style={{
                    background: `radial-gradient(${colors.events.innerColor.hsl} 0%, ${colors.events.outerColor.hsl} 100%)`,
                    height: `${setBG.height}px`
                }}/>
            </div>
            <div className='events--content-bg' style={{ top: `${setBG.offsetTop}px` }}>
                <div className='events--before-bg' style={{
                    background: `radial-gradient(${colors.events.innerColor.hsl} 0%, ${colors.events.outerColor.hsl} 100%)`,
                    height: `${setBG.height}px`,
                    top: `-${setBG.offsetTop}px`
                }}/>
            </div>
       
            {hasColorPicker &&
            <ColorPicker onChange={col => setColors(colors.events.innerColor = col)} value={colors.events.innerColor} save={saveData}
                         reset={() => resetData('innerColor')}/>}
            {hasColorPicker && <ColorPicker onChange={col => setColors(colors.events.outerColor = col)}
                                            value={colors.events.outerColor}
                                            reset={() => resetData('outerColor')}
                                            outer/>}
            <div className='events--spacer'/>
            <section className="events--section grid main-grid events--container"
                     ref={eventsSection}>
                <h1 className={`events--title ${canEdit && 'edit'}`}
                    onClick={(e) => canEdit && handleEditeMode(e)}
                    onBlur={(e) => canEdit && onTitleBlur(e, e.currentTarget.innerText)}>
                    {title}
                    <Route exact path={`${match.path}changecolor/`} component={() => <button onClick={() => {
                        setHasColorPicker(!hasColorPicker)
                    }}>change color
                    </button>}/></h1>
                <div className="events--content">
                    {Object.values(entries).map((event, index) => {
                        const lastChild = Object.values(entries).length === index + 1;
                        const loopThrouLines = index - (Math.floor(index / eventLines.length) * eventLines.length);
                        return renderEventRow(event.date, event.location, event.description, index % 2 === 0, index, lastChild, eventLines[loopThrouLines])
                    })}
                    {canEdit && <button className='button-base events--add' onClick={pushEvent}>Event hinzu fügen</button>}
                </div>

            </section>
        </div>
    )
};

export default connect(mapStateToProps, mapDispatchToProps)(Events);
