import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyCqjuZvYRtnXplkMLm-TqQRKD1Rj4y_cWg",
    authDomain: "eliane-tobler-db.firebaseapp.com",
    databaseURL: "https://eliane-tobler-db.firebaseio.com",
    projectId: "eliane-tobler-db",
    storageBucket: "eliane-tobler-db.appspot.com",
    messagingSenderId: "1098726632222",
    appId: "1:1098726632222:web:ba8943497ad9f5ad7bb70f"
};

firebase.initializeApp(firebaseConfig);

export const firestore = firebase.firestore();

export const auth = firebase.auth();

export default firebase;
