import {firestore} from '../firebase/firebase-utils';

export const getColors = async () => {


    const colors = {};

    const dataBase = firestore;

    colors['aside'] = {innerColor: {hsl: 'hsl(0%,100%, 100%)'}, outerColor: {hsl: 'hsl(0%,0%, 0%)'}};
    colors['work'] = {innerColor: {hsl: 'hsl(0%,100%, 100%)'}, outerColor: {hsl: 'hsl(0%,0%, 0%)'}};
    colors['about'] = {innerColor: {hsl: 'hsl(0%,100%, 100%)'}, outerColor: {hsl: 'hsl(0%,0%, 0%)'}};
    colors['events'] = {innerColor: {hsl: 'hsl(0%,100%, 100%)'}, outerColor: {hsl: 'hsl(0%,0%, 0%)'}};
    colors['header'] = {innerColor: {hsl: 'hsl(0%,100%, 100%)'}, outerColor: {hsl: 'hsl(0%,0%, 0%)'}};


    await dataBase.collection('colors').get().then(
        querysnapshot => {
            querysnapshot.forEach(
                doc => {
                    colors[doc.id] = doc.data();
                });
        }).catch(error => {
        console.log(error);
    });

    return colors;

};
