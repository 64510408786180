import React, {useRef, useState} from 'react';
import {connect} from 'react-redux';
import portrait from '../../Media/Eliane.png';
import ColorPicker from '../colorpicker/colorpicker';
import {Route} from "react-router";
import {colors} from "../../redux/colors-reducer/colors-action";
import {setColorData} from "../../api/set-colors";
import AboutContent from "./about-content";
import {useBackground} from "../utils/use-background";

const mapStateToProps = state => ({
    colors: state.colors,
    scroll: state.scroll,
    content: state.content,
    canEdit: state.canEdit
});

const mapDispatchToProps = dispatch => ({
    setColors: cols => dispatch(colors(cols))
});

export const About = ({ match, colors, setColors, content, canEdit }) => {
    const aboutContent = content.about;
    const aboutRef = useRef(null);
    const aboutRefBefore = useRef(null);
    const setBG = useBackground(aboutRef, aboutRefBefore);
    
    const saveData = async () => {
        await setColorData(colors.about, 'about');
    };
    
    const resetData = async (key) => {
        colors.about = { ...colors.about, [key]: colors.about.backup[key] };
        await setColorData(colors.about, 'about');
        setColors(colors.about);
    };
    
    const [hasColorPicker, setHasColorPicker] = useState(false);
    
    const aboutSection = useRef(null);
    
    return (
        <div className="about" ref={aboutRef}>
            <div className='about--before' ref={aboutRefBefore}>
                <div className='about--before-bg' style={{
                    background: `radial-gradient(${colors.about.innerColor.hsl} 0%, ${colors.about.outerColor.hsl} 100%)`,
                    height: `${setBG.height}px`
                }}/>
            </div>
            <div className='about--content-bg' style={{ top: `${setBG.offsetTop}px` }}>
                <div className='about--before-bg' style={{
                    background: `radial-gradient(${colors.about.innerColor.hsl} 0%, ${colors.about.outerColor.hsl} 100%)`,
                    height: `${setBG.height}px`,
                    top: `-${setBG.offsetTop}px`
                }}/>
            </div>
            
            {hasColorPicker &&
            <ColorPicker onChange={col => setColors(colors.about.innerColor = col)} value={colors.about.innerColor}
                save={saveData}
                reset={() => resetData('innerColor')}/>}
            {hasColorPicker && <ColorPicker onChange={col => setColors(colors.about.outerColor = col)}
                value={colors.about.outerColor}
                reset={() => resetData('outerColor')}
                outer/>
            }
            <div className='about--spacer'/>
            <section className="about--section grid main-grid about--container"
                ref={aboutSection}>
                <article className="about--content">
                    <Route exact path={`${match.path}changecolor/`} component={() => <button onClick={() => {
                        setHasColorPicker(!hasColorPicker)
                    }}>change color
                    </button>}/>
                    
                    <AboutContent content={aboutContent} editable={canEdit}/>
                
                </article>
                <figure className="about--portrait">
                    <img src={portrait} className='about--portrait-img'
                        alt='Portrait von Eliane Tobler'/>
                </figure>
            </section>
        </div>
    )
};


export default connect(mapStateToProps, mapDispatchToProps)(About);
