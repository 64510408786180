import {useLayoutEffect, useState} from 'react';
import {useWindowSize} from "./use-window-size";

export const useBackground = (workRef, workRefBefore) => {
    const [height, setHeight] = useState(0);
    const [offsetTop, setOffsetTop] = useState(0);
    const size = useWindowSize();
    
    useLayoutEffect(() => {
        function handleHeightCalc() {
            setHeight(workRef.current.offsetHeight);
            setOffsetTop(workRefBefore.current.offsetHeight);
        }
        
        handleHeightCalc();
        
    }, [workRef, workRefBefore, size]);
    
    
    return { height, offsetTop };
};
