import {firestore} from '../firebase/firebase-utils';
import mock from '../mockdata/mock';

export const getContent = async () => {
    const dataBase = firestore;

    const content = mock;

    await dataBase.collection('content').get().then(
        querysnapshot => {
            querysnapshot.forEach(
                doc => {
                    content[doc.id] = doc.data();
                });
        }).catch(error => {
        console.log(error);
    });

    return content;

};
