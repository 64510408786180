import {Types} from '../../types/types';

const initialState = null;

export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case Types.USER:
            return action.payload;
        default:
            return state;

    }
};

export default userReducer;
