import {firestore} from '../firebase/firebase-utils';

export const setContent = async (data, key) => {
    try {
        const contentCollection = firestore.collection('content');

        const getDoc = contentCollection.doc(key);
        await getDoc.set(data);
    } catch (error) {
        console.log(error);
    }
};
