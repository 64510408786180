import React, {useLayoutEffect, useMemo, useRef, useState} from 'react';

const calcHSL = (h, x, y, height, width) => {
    y = 1 - (y / height);
    x /= width;

    const l = (y / 2) * (2 - x);

    const s = (y * x) / (1 - Math.abs(2 * l - 1));

    return toHSL({h: Math.round(h), s: Math.round(s * 100), l: Math.round(l * 100)});
};

const calcHue = (y, height) => {
    return 360 * (y / height);
};

const toHSL = ({h, s, l}) => {
    return {h, s, l, hsl: `hsl(${h}, ${s}%, ${l}%)`};
};

const toFloat = ({h, s, l}) => {
    const hueY = h / 360;
    const x = s / 100;
    const y = l / 100;

    return {hueY, x, y};
};

const calcSliderPos = (value, height) => {
    return height * toFloat(value).hueY;
};

const setPickerPosXY = (value, width, height) => {
    value = toFloat(value);
    value.x *= value.y < 0.5 ? value.y : 1 - value.y;

    const y = value.y + value.x;

    const x = 2 * value.x / (value.y + value.x);

    const offsetX = width * x;
    const offsetY = height * (1 - y);

    return {x: offsetX, y: offsetY};
};


export const ColorPicker = ({onChange, outer = false, value, save, reset}) => {

    const [sliderPos, setSliderPos] = useState(0);
    const [pickerPos, setPickerPos] = useState({x: 0, y: 0})
    const slider = useRef(null);
    const picker = useRef(null);
    const [sliderIsDragable, setSliderIsDragable] = useState(false);
    const [pickerIsDragable, setPickerIsDragable] = useState(false);
    const [hue, setHue] = useState(value ? value.h : 0);
    const [hsl, setHSL] = useState(value ? value : {h: 0, s: 100, l: 50, hsl: 'hsl(0, 100%, 50%)'});

    const style = {
        backgroundColor: `hsl(${hue}, 100%, 50%)`
    };


    useLayoutEffect(() => {
        setSliderPos(calcSliderPos(hsl, slider.current.offsetHeight));
        setPickerPos(setPickerPosXY(hsl, picker.current.offsetWidth, picker.current.offsetHeight));
    }, []);

    useMemo(() => {
        if (!slider.current) {
            return;
        }
        setHue(calcHue(sliderPos, slider.current.offsetHeight));
    }, [sliderPos]);

    useMemo(() => {
        if (!picker.current) {
            return;
        }
        setHSL(calcHSL(hue, pickerPos.x, pickerPos.y, picker.current.offsetHeight, picker.current.offsetWidth));
        ;
    }, [pickerPos, hue]);

    useMemo(() => {
        onChange(hsl);
    }, [hsl]);


    const moveMouse = (e, el, yOnly = false, drag = false) => {
        if (!drag || !el) {
            return null;
        }
        const offsetTop = e.clientY - (el.current.parentNode.offsetTop + el.current.offsetTop);
        const offsetLeft = e.clientX - (el.current.parentNode.offsetLeft + el.current.offsetLeft);
        const isHeigher = offsetTop >= el.current.offsetHeight;
        const isWider = offsetLeft >= el.current.offsetWidth;

        const posX = isWider ? el.current.offsetWidth : offsetLeft;
        const posY = isHeigher ? el.current.offsetHeight : offsetTop;

        if (yOnly) {
            return posY > 0 ? posY : 0;
        }
        return {x: posX > 0 ? posX : 0, y: posY > 0 ? posY : 0}

    };

    return (
        <div className={`colorpicker ${outer && 'colorpicker__outer'}`}>
            <div className='colorpicker--box'
                 style={style}
                 ref={picker}
                 onMouseMove={e => {
                     pickerIsDragable && setPickerPos(moveMouse(e, picker, false, pickerIsDragable) || pickerPos)
                 }}>
                <div className='colorpicker--box-picker'
                     style={{
                         top: pickerPos.y,
                         left: pickerPos.x,
                         borderColor: `${picker.current && pickerPos.y / picker.current.offsetHeight > .6 ? 'white' : 'black'}`
                     }}
                     onMouseDown={() => setPickerIsDragable(!pickerIsDragable)}
                     onMouseUp={() => setPickerIsDragable(!pickerIsDragable)}/>
            </div>
            <div className='colorpicker--panel'
                 ref={slider}
                 onMouseMove={(e) => {
                     sliderIsDragable && setSliderPos(moveMouse(e, slider, true, sliderIsDragable));
                 }}>
                <div className='colorpicker--panel-slider'
                     onMouseDown={() => setSliderIsDragable(!sliderIsDragable)}
                     onMouseUp={() => setSliderIsDragable(!sliderIsDragable)}
                     style={{top: `${sliderPos}px`}}/>
            </div>
            <button className='colorpicker--save' onClick={save}>Farbe speichern</button>
            <button className='colorpicker--reset' onClick={reset}>zurück setzen</button>
        </div>
    )
}
export default ColorPicker;
