import React, {useLayoutEffect, useState} from 'react';
import {Link} from 'react-router-dom';

const MenuItems = [
    {
        to: '',
        name: 'Home'
    },
    {
        to: '/work',
        name: 'Meine Angebote'
    },
    {
        to: '/method',
        name: 'Arbeitsweise'
    },
    {
        to: '/events',
        name: 'Termine'
    },
    {
        to: '/about',
        name: 'Über mich'
    },
    {
        type: 'extern',
        to: 'https://www.facebook.com/elianetobler.ch/',
        name: 'Blog'
    },
    {
        to: '/contact',
        name: 'Kontakt'
    },
];


export const Nav = ({match}) => {
    const [toggleNav, setToggleNav] = useState();
    const canEdit = (match.params.isEditMode || match.params.section) === 'canEdit';

    const jumpToSelection = (section) => {
        const el = document.getElementsByClassName(section)[0];
        const scrollOffset = window.innerWidth >= 760 ? 200 : 50;

        window.scrollTo({
            left: 0,
            top: el ? el.offsetTop + el.parentNode.offsetTop + -scrollOffset : 0,
            behavior: 'smooth'
        });

    };

    useLayoutEffect(() => {
        jumpToSelection(`${match.params.section}--section`)
    }, [match.params.section]);

    return (
        <nav className='nav'>
            <div className={`nav--menu ${toggleNav && 'nav--menu-toggle'}`}>
                <ul>
                    {
                        MenuItems.map((item, index) => {
                            return (
                                <li onClick={() => {
                                    setToggleNav(!toggleNav)
                                }} key={index}>
                                    {
                                        item.type && item.type === 'extern' ?
                                            <a className='nav--link'
                                               href={item.to}
                                               target='_blank'
                                               rel='noopener noreferrer'>{item.name}</a>
                                            :
                                            <Link className='nav--link'
                                                  to={`${item.to}${canEdit ? '/canEdit' : ''}`}>{item.name}</Link>
                                    }
                                </li>
                            );
                        })
                    }
                </ul>
            </div>
            <div className='nav--toggle'
                 onClick={() => {
                     setToggleNav(!toggleNav)
                 }}>
                <div className='nav--burger'/>
            </div>
        </nav>
    );
};
export default Nav;
