import React, {useEffect, useState} from 'react';
import {canEdit} from '../../redux/can-edit-reducer/can-edit-action';
import {connect} from 'react-redux';
import Login from "../login/login";
import {auth} from "../../firebase/firebase-utils";
import {user} from "../../redux/user-reducer/user-action";


const mapDispatchToProps = dispatch => ({
    setCanEdit: edit => dispatch(canEdit(edit)),
    setUser: currentUser => dispatch(user(currentUser))
});

const mapStateToProps = state => ({
    user: state.user
});

const EditPage = ({setCanEdit, match, user, setUser}) => {
    const canEdit = (match.params.isEditMode || match.params.section) === 'canEdit';
    const [showLogin, setShowLogin] = useState(false);


    useEffect(() => {
        auth.onAuthStateChanged(userAuth => {
            setUser(userAuth);
        });
        setCanEdit(canEdit && user);
    });

    const handleLogOut = async () => {
        await auth.signOut();
        setShowLogin(false)
    };

    return (
        canEdit ?
            canEdit && !user ?
                showLogin ? <Login cancel={() =>{ setShowLogin(false)}}/> :
                    <div className='button-base edit-page edit-page--logout' onClick={() => setShowLogin(true)}>Login</div> :
                <div className='button-base edit-page edit-page--logout' onClick={handleLogOut}>Logout</div> : null
    )
};

export default connect(mapStateToProps, mapDispatchToProps)(EditPage);
