import {setContent} from "../../api/set-content";
import store from "../../redux/store";
import {content} from "../../redux/content-reducer/content-action";

export const handleEditeMode = (e) => {
    e.currentTarget.setAttribute('contentEditable', true);
    e.currentTarget.focus();
};

export const handleEditeModeBlur = (e) => {
    e.currentTarget.setAttribute('contentEditable', false);
    e.currentTarget.style.backgroundColor = '';
};

export const saveValue = async (e, section, data) => {
    e && handleEditeModeBlur(e);

    await setContent(data, section);
    store.dispatch(content({[section]: data}));
};
