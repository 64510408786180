import {Types} from '../../types/types';

const initialState = {};

export const contentReducer = (state = initialState, action) => {
    switch (action.type) {
        case Types.CONTENT:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;

    }
};

export default contentReducer;
