import React, {useRef, useState} from 'react';
import ColorPicker from '../colorpicker/colorpicker';
import {connect} from 'react-redux';
import {Route} from "react-router";
import {setColorData} from "../../api/set-colors";
import {colors} from "../../redux/colors-reducer/colors-action";
import WorkContent from './work-content';
import {useBackground} from "../utils/use-background";

const mapStateToProps = state => ({
    colors: state.colors,
    scroll: state.scroll,
    content: state.content,
    canEdit: state.canEdit
});

const mapDispatchToProps = dispatch => ({
    setColors: cols => dispatch(colors(cols)),
});

export const Work = ({ match, setColors, colors, content, canEdit }) => {
    const [hasColorPicker, setHasColorPicker] = useState(false);
    const workContent = content.work;
    const workRef = useRef(null);
    const workRefBefore = useRef(null);
    const setBG = useBackground(workRef, workRefBefore);
    
    const saveData = async () => {
        await setColorData(colors.work, 'work');
    };
    
    const resetData = async (key) => {
        colors.work = { ...colors.work, [key]: colors.work.backup[key] };
        await setColorData(colors.work, 'work');
        setColors(colors.work);
    };
    
    
    const workSection = useRef(null);
    
    return (<div className="work" ref={workRef}>
            <div className='work--before' ref={workRefBefore}>
                <div className='work--before-bg' style={{
                    background: `radial-gradient(${colors.work.innerColor.hsl} 0%, ${colors.work.outerColor.hsl} 100%)`,
                    height: `${setBG.height}px`
                }}/>
            </div>
            <div className='work--content-bg' style={{ top: `${setBG.offsetTop}px` }}>
                <div className='work--before-bg' style={{
                    background: `radial-gradient(${colors.work.innerColor.hsl} 0%, ${colors.work.outerColor.hsl} 100%)`,
                    height: `${setBG.height}px`,
                    top: `-${setBG.offsetTop}px`
                }}/>
            </div>
            
            {hasColorPicker && <ColorPicker onChange={col => setColors(colors.work.innerColor = col)}
                value={colors.work.innerColor} save={saveData} reset={() => resetData('innerColor')}/>}
            {hasColorPicker && <ColorPicker onChange={col => setColors(colors.work.outerColor = col)}
                outer value={colors.work.outerColor} save={saveData} reset={() => resetData('outerColor')}/>}
            <div className='work--spacer'/>
            <section className="work--section grid main-grid"
                ref={workSection}>
                <article className="work--content">
                    <Route exact path={`${match.path}changecolor/`} component={() => <button onClick={() => {
                        setHasColorPicker(!hasColorPicker)
                    }}>change color
                    </button>}/>
                    <WorkContent content={workContent} editable={canEdit}/>
                </article>
            </section>
            <div className='work--after'/>
        </div>
    )
};

export default connect(mapStateToProps, mapDispatchToProps)(Work);
