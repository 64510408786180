import React, {useState} from 'react';
import {auth} from "../../firebase/firebase-utils";


export const Login = ({cancel}) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleLogin = async () => {
        try {
            await auth.signInWithEmailAndPassword(email, password);
            setEmail('');
            setPassword('');
        } catch (error) {
            console.log(error);
        }
    };

    const handleEnter = (e) => {
        e.key === 'Enter' && handleLogin();
    };

    return (
        <div className='login'>
            <form autoComplete='on'>
                <label>User Name</label>
                <input type='email' name='email' onKeyDown={handleEnter} onChange={e => {
                    setEmail(e.target.value)
                }}/>
                <label>Password</label>
                <input type='password' name='password' onKeyDown={handleEnter} onChange={e => {
                    setPassword(e.target.value)
                }}/>
                <div className='login--button-group'>
                    <div className='button-base login--cancel' onClick={cancel}>abbrechen</div>
                    <button type='button' className='button-base login--button' onClick={handleLogin}>anmelden</button>
                </div>
            </form>
        </div>
    )
};

export default Login;
