import { Types } from '../../types/types';

const initialState = { scrollY: 0 };

export const scrollReducer = (state = initialState, action) => {
    switch (action.type) {
        case Types.SCROLLPOS:
            return {
                ...state,
                scrollY: action.payload
            };
        default:
            return state;

    }
};

export default scrollReducer;
