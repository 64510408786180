import {Types} from '../../types/types';

const initialState = false;

export const canEditReducer = (state = initialState, action) => {
    switch (action.type) {
        case Types.CANEDIT:
            return action.payload;
        default:
            return state;

    }
};

export default canEditReducer;
