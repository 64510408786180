import {firestore} from '../firebase/firebase-utils';

export const setColorData = async (data, key) => {
    try {
        const colorCollection = firestore.collection('colors');

        const getDoc = colorCollection.doc(key);
        await getDoc.set(data);
    } catch (error) {
        console.log(error);
    }
};
