import React from 'react';
export const convertToRichText = (string) => {
    const findLinks = string.match(/\(.*?\)\[.*?\]/gm);
    if (!findLinks) {
        return string;
    }
    const splitString = string.split(/\(.*?\)\[.*?\]/gm);

    const hRef = findLinks.map(piece => piece.match(/\[.*\]/g)[0].replace(/\[/g, '').replace(/\]/g, ''));
    const name = findLinks.map(piece => piece.match(/\(.*\)/g)[0].replace(/\(/g, '').replace(/\)/g, ''));
    const createLink = (link, linkname) => (<a href={link} target='_blank' rel='noopener noreferrer'>{linkname}</a>);

    return splitString.map((piece, index) => {
        return piece || index === 0 ? <span key={index}>{piece}{index < hRef.length && createLink(hRef[index], name[index])}</span> : null;
    });
};
