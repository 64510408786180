import {Types} from '../../types/types';

const initialState = {};

export const colorsReducer = (state = initialState, action) => {
    switch (action.type) {
        case Types.COLORS:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;

    }
};

export default colorsReducer;
