import React from 'react';
import {handleEditeMode} from "../utils/handle-edit-mode";

export const Address = ({entries, handleChange, editable}) => {

    return (
        <address className='address'>
            <div itemProp="address" itemScope itemType="http://schema.org/PostalAddress">
                <h3 className={`address--first-title ${editable && 'edit'}`} onClick={(e) => editable && handleEditeMode(e)}
                    onBlur={(e) => editable && handleChange(e, {title: e.currentTarget.innerText}, 0)}>{entries[0].title}</h3>

                <p className={`address--phone ${editable && 'edit'}`}
                   onClick={(e) => editable && handleEditeMode(e)}
                   onBlur={(e) => editable && handleChange(e, {text: e.currentTarget.innerText}, 0)}
                >{entries[0].phone}</p>

                <h3 className={`address--second-title ${editable && 'edit'}`}
                    onClick={(e) => editable && handleEditeMode(e)}
                    onBlur={(e) => editable && handleChange(e, {title: e.currentTarget.innerText}, 1)}
                >
                    {entries[1].title}
                </h3>
                <p className='address--container'>
                    <span itemProp='streetAddress' className={`${editable && 'edit'}`}
                          onClick={(e) => editable && handleEditeMode(e)}
                          onBlur={(e) => editable && handleChange(e, {street: e.currentTarget.innerText}, 1)}
                    >
                        {entries[1].street}
                    </span>
                    <br/>
                    <span className={`${editable && 'edit'}`}
                          onClick={(e) => editable && handleEditeMode(e)}
                          onBlur={(e) => editable && handleChange(e, {plz: e.currentTarget.innerText}, 1)}
                    >
                        {entries[1].plz}
                    </span>
                    {' '}
                    <span className={`${editable && 'edit'}`}
                          onClick={(e) => editable && handleEditeMode(e)}
                          onBlur={(e) => editable && handleChange(e, {town: e.currentTarget.innerText}, 1)}
                    >
                        {entries[1].town}
                    </span>
                </p>


                <h3 className={`address--second-title ${editable && 'edit'}`}
                    onClick={(e) => editable && handleEditeMode(e)}
                    onBlur={(e) => editable && handleChange(e, {title: e.currentTarget.innerText}, 2)}
                >
                    {entries[2].title}
                </h3>
                <p className='address--container'>
                    <span itemProp='streetAddress' className={`${editable && 'edit'}`}
                          onClick={(e) => editable && handleEditeMode(e)}
                          onBlur={(e) => editable && handleChange(e, {street: e.currentTarget.innerText}, 2)}
                    >
                        {entries[2].street}
                    </span>
                    <br/>
                    <span className={`${editable && 'edit'}`}
                          onClick={(e) => editable && handleEditeMode(e)}
                          onBlur={(e) => editable && handleChange(e, {plz: e.currentTarget.innerText}, 2)}
                    >
                        {entries[2].plz}
                    </span>
                    {' '}
                    <span className={`${editable && 'edit'}`}
                          onClick={(e) => editable && handleEditeMode(e)}
                          onBlur={(e) => editable && handleChange(e, {town: e.currentTarget.innerText}, 2)}
                    >
                        {entries[2].town}
                    </span>
                </p>
            </div>
        </address>
    )
};

export default Address;
