import React, {useEffect, useState} from 'react';
import './App.scss';
import {Provider} from 'react-redux';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import store from '../redux/store';
import About from './about/about';
import Aside from './contact/contact';
import Nav from './nav/nav';
import Work from './work/work';
import Method from './method/method';
import Events from './events/events';
import Header from './header/header';
import EditPage from './edit-page/edit-page';
import {getColors} from "../api/get-colors";
import {colors} from "../redux/colors-reducer/colors-action";
import {getContent} from "../api/get-content";
import {content} from "../redux/content-reducer/content-action";


function App() {
    const [isLoading, setIsLoading] = useState(true);

    useEffect( () => {
        const loadData = async () => {
            const setColors = await getColors();
            const setContent = await getContent();
            await store.dispatch(colors(setColors));
            await store.dispatch(content(setContent));
            setIsLoading(false);
        };
        loadData();

    }, []);

    return (
        <Provider store={store}>
            <div className="App">
                {isLoading ? null :
                    <Router>
                        <Route path='/:section?/:isEditMode?' component={Nav}/>
                        <Route path='/:section?/:isEditMode?' component={EditPage}/>
                        <Route component={Header}/>
                        <Route component={Work}/>
                        <Route component={Method}/>
                        <Route component={About}/>
                        <Route component={Events}/>
                        <Route component={Aside}/>
                    </Router>
                }
            </div>
        </Provider>
    );
}

export default App;
