import scrollReducer from './scroll-reducer/scroll-reducer';
import { combineReducers } from 'redux';
import colorsReducer from "./colors-reducer/colors-reducer";
import contentReducer from "./content-reducer/content-reducer";
import canEditReducer from "./can-edit-reducer/can-edit-reducer";
import userReducer from "./user-reducer/user-reducer";


export const rootReducer = combineReducers({
    scroll: scrollReducer,
    colors: colorsReducer,
    content: contentReducer,
    canEdit: canEditReducer,
    user: userReducer,
});
