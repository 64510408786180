import React, {useMemo, useRef, useState} from 'react';
import {connect} from 'react-redux';
import Address from '../address/adress';
import {ContactForm} from '../contact-form/contact-form';
import ColorPicker from "../colorpicker/colorpicker";
import {Route} from "react-router";
import {setColorData} from "../../api/set-colors";
import {colors} from "../../redux/colors-reducer/colors-action";
import {handleEditeMode, handleEditeModeBlur, saveValue} from "../utils/handle-edit-mode";
import {useBackground} from "../utils/use-background";

const mapStateToProps = state => ({
    colors: state.colors,
    scroll: state.scroll,
    content: state.content,
    canEdit: state.canEdit,
});

const mapDispatchToProps = dispatch => ({
    setColors: cols => dispatch(colors(cols))
});

export const Contact = ({ canEdit, match, content, colors, setColors }) => {
    const asideSection = useRef(null);
    const [hasColorPicker, setHasColorPicker] = useState(false);
    const [title, setTitle] = useState(content.contact.title);
    const [entries, setEntries] = useState(content.contact.entries);
    const [shouldSave, setShouldSave] = useState(false);
    const contactRef = useRef(null);
    const contactRefBefore = useRef(null);
    const setBG = useBackground(contactRef, contactRefBefore);
    
    const onTitleBlur = (e, value) => {
        setTitle(value);
        setShouldSave(true);
        handleEditeModeBlur(e);
    };
    
    const onEntryBlur = (e, value, index) => {
        setEntries({ ...entries, [index]: { ...entries[index], ...value } });
        setShouldSave(true);
        handleEditeModeBlur(e);
    };
    
    const saveData = async () => {
        await setColorData(colors.aside, 'aside');
    };
    
    const resetData = async (key) => {
        colors.aside = { ...colors.aside, [key]: colors.aside.backup[key] };
        await setColorData(colors.aside, 'aside');
        setColors(colors.aside);
    };
    
    useMemo(async () => {
        shouldSave && await saveValue(null, 'contact', { title, entries });
        setShouldSave(false);
    }, [title, entries, shouldSave]);
    
    return (
        <aside className='contact' ref={contactRef}>
            <div className='contact--before' ref={contactRefBefore}>
                <div className='contact--before-bg' style={{
                    background: `radial-gradient(${colors.aside.innerColor.hsl} 0%, ${colors.aside.outerColor.hsl} 100%)`,
                    height: `${setBG.height}px`
                }}/>
            </div>
            <div className='contact--content-bg' style={{ top: `${setBG.offsetTop}px` }}>
                <div className='contact--before-bg' style={{
                    background: `radial-gradient(${colors.aside.innerColor.hsl} 0%, ${colors.aside.outerColor.hsl} 100%)`,
                    height: `${setBG.height}px`,
                    top: `-${setBG.offsetTop}px`
                }}/>
            </div>
            
            
            {hasColorPicker &&
            <ColorPicker onChange={col => setColors(colors.aside.innerColor = col)} value={colors.aside.innerColor}
                save={saveData}
                reset={() => resetData('innerColor')}/>}
            {hasColorPicker && <ColorPicker onChange={col => setColors(colors.aside.outerColor = col)}
                value={colors.aside.outerColor}
                save={saveData} reset={() => resetData('outerColor')}
                outer/>}
            <div className='contact--spacer'/>
            <section className='contact--section grid main-grid contact--container'
                ref={asideSection}>
                
                
                <h1 className={`contact--title ${canEdit && 'edit'}`}
                    onClick={(e) => canEdit && handleEditeMode(e)}
                    onBlur={(e) => canEdit && onTitleBlur(e, e.currentTarget.innerText)}>
                    {title}
                    <Route exact path={`${match.path}changecolor/`} component={() => <button onClick={() => {
                        setHasColorPicker(!hasColorPicker)
                    }}>change color
                    </button>}/>
                </h1>
                <div className='contact--content'>
                    <ContactForm/>
                    <Address entries={entries} editable={canEdit} handleChange={onEntryBlur}/>
                </div>
            </section>
        </aside>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
